{
  "metadata": {
    "version": 4.5,
    "type": "BufferGeometry",
    "generator": "BufferGeometry.toJSON"
  },
  "uuid": "8B1A6E5B-A7CC-4471-9CA0-BD64D80ABB79",
  "type": "BufferGeometry",
  "data": {
    "attributes": {
      "position": {
        "itemSize": 3,
        "type": "Float32Array",
        "array": [-25,-15,-2.5,0,0,-2.5,0,-5,-2.5,0,-5,-2.5,0,0,-2.5,25,-15,-2.5,0,-5,-2.5,25,-15,-2.5,25,-20,-2.5,0,-5,-2.5,-25,-20,-2.5,-25,-15,-2.5,25,-15,2.5,25,-20,2.5,25,-15,-2.5,25,-15,-2.5,25,-20,2.5,25,-20,-2.5,25,-20,2.5,0,-5,2.5,25,-20,-2.5,25,-20,-2.5,0,-5,2.5,0,-5,-2.5,0,-5,2.5,-25,-20,2.5,0,-5,-2.5,0,-5,-2.5,-25,-20,2.5,-25,-20,-2.5,-25,-20,2.5,-25,-15,2.5,-25,-20,-2.5,-25,-20,-2.5,-25,-15,2.5,-25,-15,-2.5,-25,-15,2.5,0,0,2.5,-25,-15,-2.5,-25,-15,-2.5,0,0,2.5,0,0,-2.5,0,0,2.5,25,-15,2.5,0,0,-2.5,0,0,-2.5,25,-15,2.5,25,-15,-2.5,25,-20,2.5,25,-15,2.5,0,-5,2.5,0,-5,2.5,25,-15,2.5,0,0,2.5,0,-5,2.5,0,0,2.5,-25,-15,2.5,-25,-15,2.5,-25,-20,2.5,0,-5,2.5],
        "normalized": false
      },
      "normal": {
        "itemSize": 3,
        "type": "Float32Array",
        "array": [0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,-0.514495,-0.857492,0,-0.514495,-0.857492,0,-0.514495,-0.857492,0,-0.514495,-0.857492,0,-0.514495,-0.857492,0,-0.514495,-0.857492,0,0.514495,-0.857492,0,0.514495,-0.857492,0,0.514495,-0.857492,0,0.514495,-0.857492,0,0.514495,-0.857492,0,0.514495,-0.857492,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-0.514495,0.857492,0,-0.514495,0.857492,0,-0.514495,0.857492,0,-0.514495,0.857492,0,-0.514495,0.857492,0,-0.514495,0.857492,0,0.514495,0.857492,0,0.514495,0.857492,0,0.514495,0.857492,0,0.514495,0.857492,0,0.514495,0.857492,0,0.514495,0.857492,0,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1],
        "normalized": false
      }
    },
    "boundingSphere": {
      "center": [0,-10,0],
      "radius": 27.041634
    }
  }
}
