{
  "metadata": {
    "version": 4.5,
    "type": "BufferGeometry",
    "generator": "BufferGeometry.toJSON"
  },
  "uuid": "B12A1453-6E56-40AC-840B-BA7DF5DB9E2A",
  "type": "BufferGeometry",
  "data": {
    "attributes": {
      "position": {
        "itemSize": 3,
        "type": "Float32Array",
        "array": [-26,-21.766189,-3.5,-26,-14.433809,-3.5,0,-6.166190,-3.5,0,-6.166190,-3.5,-26,-14.433809,-3.5,0,1.166190,-3.5,0,-6.166190,-3.5,0,1.166190,-3.5,26,-14.433810,-3.5,26,-14.433810,-3.5,26,-21.766191,-3.5,0,-6.166190,-3.5,-26,-14.433809,3.5,0,1.166190,3.5,-26,-14.433809,-3.5,-26,-14.433809,-3.5,0,1.166190,3.5,0,1.166190,-3.5,0,1.166190,3.5,26,-14.433810,3.5,0,1.166190,-3.5,0,1.166190,-3.5,26,-14.433810,3.5,26,-14.433810,-3.5,26,-14.433810,3.5,26,-21.766191,3.5,26,-14.433810,-3.5,26,-14.433810,-3.5,26,-21.766191,3.5,26,-21.766191,-3.5,26,-21.766191,3.5,0,-6.166190,3.5,26,-21.766191,-3.5,26,-21.766191,-3.5,0,-6.166190,3.5,0,-6.166190,-3.5,0,-6.166190,3.5,-26,-21.766189,3.5,0,-6.166190,-3.5,0,-6.166190,-3.5,-26,-21.766189,3.5,-26,-21.766189,-3.5,-26,-21.766189,3.5,-26,-14.433809,3.5,-26,-21.766189,-3.5,-26,-21.766189,-3.5,-26,-14.433809,3.5,-26,-14.433809,-3.5,-26,-21.766189,3.5,0,-6.166190,3.5,-26,-14.433809,3.5,-26,-14.433809,3.5,0,-6.166190,3.5,0,1.166190,3.5,0,1.166190,3.5,0,-6.166190,3.5,26,-14.433810,3.5,26,-14.433810,3.5,0,-6.166190,3.5,26,-21.766191,3.5],
        "normalized": false
      },
      "normal": {
        "itemSize": 3,
        "type": "Float32Array",
        "array": [0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,-0.514495,0.857492,0,-0.514495,0.857492,0,-0.514495,0.857492,0,-0.514495,0.857492,0,-0.514495,0.857492,0,-0.514495,0.857492,0,0.514495,0.857492,0,0.514495,0.857492,0,0.514495,0.857492,0,0.514495,0.857492,0,0.514495,0.857492,0,0.514495,0.857492,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,-0.514495,-0.857492,0,-0.514495,-0.857492,0,-0.514495,-0.857492,0,-0.514495,-0.857492,0,-0.514495,-0.857492,0,-0.514495,-0.857492,0,0.514495,-0.857492,0,0.514495,-0.857492,0,0.514495,-0.857492,0,0.514495,-0.857492,0,0.514495,-0.857492,0,0.514495,-0.857492,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,-1,0,0,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1,0,0,1],
        "normalized": false
      }
    },
    "boundingSphere": {
      "center": [0,-10.300000,0],
      "radius": 28.630814
    }
  }
}
